import * as React from "react";
import Footer from "../components/footer";
import Privacy from "../components/privacy";

const PrivacyPage = () => {
  return (
    <main>
      <div id="wrapper">
        <div id="main">
          <Privacy />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PrivacyPage;
