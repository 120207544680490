import React from "react";

const Privacy = () => {
  return (
    <section>
      <div className="container">
        <h1>Datenschutzerklärung</h1>
        <p>
          <strong>Allgemeiner Hinweis und Pflichtinformationen</strong>
        </p>
        <p>
          <strong>Benennung der verantwortlichen Stelle</strong>
        </p>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          <span id="s3-t-firma">Bernd Funk</span>
          <br />
          <span id="s3-t-strasse">Strandläuferweg, 5</span>
          <br />
          <span id="s3-t-plz">25899</span> <span id="s3-t-ort">Niebüll</span>
        </p>
        <p></p>
        <p>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </p>

        <p>
          <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
        </p>
        <p>
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>

        <p>
          <strong>
            Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
          </strong>
        </p>
        <p>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{" "}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>

        <p>
          <strong>Recht auf Datenübertragbarkeit</strong>
        </p>
        <p>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>

        <p>
          <strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong>
        </p>
        <p>
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </p>

        <p>
          <strong>SSL- bzw. TLS-Verschlüsselung</strong>
        </p>
        <p>
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </p>

        <p>
          <strong>Server-Log-Dateien</strong>
        </p>
        <p>
          In Server-Log-Dateien erhebt und speichert der Provider der Website
          automatisch Informationen, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul>
          <li>Besuchte Seite auf unserer Domain</li>
          <li>Datum und Uhrzeit der Serveranfrage</li>
          <li>Browsertyp und Browserversion</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>IP-Adresse</li>
        </ul>
        <p>
          Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
          statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b
          DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
          oder vorvertraglicher Maßnahmen gestattet.
        </p>

        <p>
          <strong>Kontaktformular</strong>
        </p>
        <p>
          Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
          Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um
          für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
          findet ohne Ihre Einwilligung nicht statt.
        </p>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist
          jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung
          per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <p>
          Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis
          Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
          Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen
          - bleiben unberührt.
        </p>

        <p>
          <strong>Google Web Fonts</strong>
        </p>
        <p>
          Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google
          Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p>
          Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns
          gewünschte Darstellung unserer Website zu präsentieren, unabhängig
          davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt
          über den Abruf der Google Web Fonts von einem Server von Google in den
          USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei
          handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns
          besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage
          von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir
          ein berechtigtes Interesse an der optimalen Darstellung und
          Übertragung unseres Webauftritts.
        </p>
        <p>
          Das Unternehmen Google ist für das us-europäische
          Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses
          Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden
          Datenschutzniveaus gewährleisten.
        </p>
        <p>
          Einzelheiten über Google Web Fonts finden Sie unter:{" "}
          <a href="https://www.google.com/fonts#AboutPlace:about">
            https://www.google.com/fonts#AboutPlace:about
          </a>{" "}
          und weitere Informationen in den Datenschutzbestimmungen von Google:{" "}
          <a href="https://policies.google.com/privacy/partners?hl=de">
            https://policies.google.com/privacy/partners?hl=de
          </a>
        </p>
        <p>
          <small>
            Quelle: Datenschutz-Konfigurator von{" "}
            <a
              href="http://www.mein-datenschutzbeauftragter.de"
              target="_blank"
              rel="noreferrer"
            >
              mein-datenschutzbeauftragter.de
            </a>
          </small>
        </p>
      </div>
    </section>
  );
};

export default Privacy;
